const API_BASE = 'https://api.joineconomy.com/v1'

// Contact the economy backend to see if there is a user signed in
export async function loginUser() {
  return fetch(
    `${API_BASE}/login`,
    {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    },
  )
    .then((response) => response)
    .catch(() => null)
}

export const logoutUser = async () => {
	return fetch(
		`${API_BASE}/logout`,
		{
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
			method: 'GET',
		}
	)
		.then((response) => response)
		.catch(() => null)
}

export async function getWallet() {
  return fetch(
    `${API_BASE}/wallet`,
    {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    },
  )
    .then((response) => response)
    .catch(() => null)
}

export async function getUserApps() {
  return fetch(
    `${API_BASE}/userApps`,
    {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    },
  )
    .then((response) => response)
    .catch(() => null)
}


