import React from 'react'
function Settings({func}) {
    return (
        <div className="flex flex-col items-center justify-center">
            <h1 className="text-white text-xl font-bold px-1 text-center mt-4 rounded">Settings</h1>
            <button
                type="button"
                className="text-white text-xl font-bold px-1 text-center mt-4 rounded"
                onClick={() => func()}>
                    <h2 >sign out</h2>
            </button>
        </div>
    );
}
export default Settings;