import React from 'react'
import PropTypes from 'prop-types'
import {
    Link,
    useLocation,
  } from "react-router-dom";


function NavBar ({loggedIn}) {
    const location = useLocation();
    const path = location.pathname
    console.log(path)
    const hidden = !loggedIn ? ' hidden' : ''
    const className = `flex flex-col items-center${hidden}`
    return (
  <footer className="navBar customBackground flex flex-row justify-between mt-auto px-7 h-24
  inset-x-0 content-center shadow-2xl py-5">
    <Link to="/wallet">
        <div className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width="32" height="32"
                viewBox="0 0 172 172"
                style={{"fill":"#000000"}}><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{"mixBlendMode": "normal"}}><path d="M0,172v-172h172v172z" fill="none"></path><g fill={path === "/wallet" ? "#ffba79" : "#cccccc" }><path d="M119.4325,10.4275c-2.55312,0 -5.56312,0.52406 -9.675,1.6125c-10.34687,2.72781 -71.01719,19.51125 -71.9175,19.78c-9.00312,3.38625 -14.70062,6.71875 -18.275,10.6425c4.1925,-2.83531 9.19125,-4.6225 14.835,-4.6225h96.32v-11.5025c0,-5.56312 -0.04031,-15.91 -11.2875,-15.91zM34.4,44.72c-12.7925,0 -20.64,12.02656 -20.64,20.64v73.53c0,10.65594 8.69406,19.35 19.35,19.35h105.78c10.65594,0 19.35,-8.69406 19.35,-19.35v-74.0675c0,-10.52156 -9.83625,-20.1025 -20.64,-20.1025zM132.44,92.88c4.74344,0 8.6,3.84313 8.6,8.6c0,4.75688 -3.85656,8.6 -8.6,8.6c-4.75687,0 -8.6,-3.84312 -8.6,-8.6c0,-4.75687 3.84313,-8.6 8.6,-8.6z"></path></g></g>
            </svg>
            <p>Wallet</p>
        
        </div>
    </Link>
    <Link to="/">
        <div className="flex flex-col items-center">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width="32" height="32"
                viewBox="0 0 172 172"
                style={{"fill":"#000000"}}><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{"mixBlendMode": "normal"}}><path d="M0,172v-172h172v172z" fill="none"></path><g fill={path === "/" ? "#ffba79" : "#cccccc" }><path d="M86,22.93333c-1.634,0 -3.18648,0.36075 -4.59114,0.97422l-54.78021,22.37344c-2.15,0.8256 -3.69531,2.87661 -3.69531,5.31901c0,2.4424 1.54531,4.49341 3.69531,5.31901l55.02657,22.48541c1.33586,0.5504 2.80252,0.86224 4.34479,0.86224c1.54227,0 3.01439,-0.31184 4.35599,-0.86224l55.02656,-22.48541c2.14427,-0.8256 3.68411,-2.87661 3.68411,-5.31901c0,-2.4424 -1.54531,-4.49341 -3.69531,-5.31901l-54.78021,-22.37344c-1.40467,-0.6192 -2.95714,-0.97422 -4.59114,-0.97422zM40.54766,74.99245l-13.91901,5.68854c-2.15,0.8256 -3.69531,2.87661 -3.69531,5.31901c0,2.4424 1.54531,4.49341 3.69531,5.31901l55.02657,22.48542c1.33586,0.5504 2.80252,0.86224 4.34479,0.86224c1.54227,0 3.01439,-0.31184 4.35599,-0.86224l55.02656,-22.48542c2.14427,-0.8256 3.68411,-2.87661 3.68411,-5.31901c0,-2.4424 -1.54531,-4.49341 -3.69531,-5.31901l-13.91901,-5.68854l-36.75156,15.01641c-2.7864,1.14667 -5.71371,1.72448 -8.70078,1.72448c-2.98707,0 -5.92038,-0.58354 -8.68958,-1.72448zM40.54766,109.39245l-13.91901,5.68854c-2.15,0.8256 -3.69531,2.87661 -3.69531,5.31901c0,2.4424 1.54531,4.49341 3.69531,5.31901l55.02657,22.48542c1.33586,0.5504 2.80252,0.86224 4.34479,0.86224c1.54227,0 3.01439,-0.31184 4.35599,-0.86224l55.02656,-22.48542c2.14427,-0.8256 3.68411,-2.87661 3.68411,-5.31901c0,-2.4424 -1.54531,-4.49341 -3.69531,-5.31901l-13.91901,-5.68854l-36.75156,15.01641c-2.7864,1.14667 -5.71371,1.72448 -8.70078,1.72448c-2.98707,0 -5.92038,-0.58354 -8.68958,-1.72448z"></path></g></g>
            </svg>
            <p>Apps</p>
        </div>
    </Link>
    <Link to="/settings">
        <div className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width="32" height="32"
                viewBox="0 0 172 172"
                style={{"fill":"#000000"}}><g fill="none" fillRule="nonzero" stroke="none" strokeWidth="1" strokeLinecap="butt" strokeLinejoin="miter" strokeMiterlimit="10" strokeDasharray="" strokeDashoffset="0" fontFamily="none" fontWeight="none" fontSize="none" textAnchor="none" style={{"mixBlendMode": "normal"}}><path d="M0,172v-172h172v172z" fill="none"></path><g fill={path === "/settings" ? "#ffba79" : "#cccccc" }><path d="M107.5,21.5c-23.7145,0 -43,19.2855 -43,43c0,3.71233 0.50839,7.42377 1.51172,11.08594l-32.7959,32.7959c-10.78809,2.80056 -18.88249,12.58416 -18.88249,24.2015c0,13.76826 11.31508,25.08333 25.08333,25.08333c11.61733,0 21.40094,-8.09439 24.2015,-18.88249l32.7959,-32.7959c3.66217,1.00334 7.3736,1.51172 11.08594,1.51172c23.7145,0 43,-19.2855 43,-43c0,-5.26033 -1.0236,-10.52492 -3.03743,-15.64909l-1.13379,-2.91146l-14.36133,14.36133c-2.79499,2.80217 -6.46478,4.19922 -10.13411,4.19922c-3.66933,0 -7.33911,-1.39705 -10.13411,-4.19922c-5.59717,-5.59717 -5.59717,-14.67106 0,-20.26823l14.36133,-14.36133l-2.91146,-1.13379c-5.12417,-2.01383 -10.38876,-3.03743 -15.64909,-3.03743zM39.41667,121.83333c6.02195,0 10.75,4.72805 10.75,10.75c0,6.02195 -4.72805,10.75 -10.75,10.75c-6.02195,0 -10.75,-4.72805 -10.75,-10.75c0,-6.02195 4.72805,-10.75 10.75,-10.75z"></path></g></g>
            </svg>
            <p>Settings</p>
        </div>
    </Link>
  </footer>
)
}

NavBar.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
}

export default NavBar