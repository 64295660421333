import React, { useEffect, useState }  from 'react';
import { getWallet, getUserApps } from '../api'
import Total from './components/total'
import AppShare from './components/appShare'

function Wallet() {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)
  const [viewAll, setViewAll] = useState(false)

  function compare( a, b ) {
    // descending order
    if ( a.numShares < b.numShares){
      return 1;
    }
    if ( a.numShares > b.numShares ){
      return -1;
    }
    return 0;
  }
  
  useEffect(async () => {
    const result = await getWallet()
    const result2 = await getUserApps()
    if(result != null && result.status == 200 && result2 != null && result2.status == 200)
    {
      const resJson = await result.json()
      const total = resJson.total
      const res2Json = await result2.json()
      const dict = new Object()
      res2Json.apps.forEach(element => {dict[element.id]=[element, null]})
      resJson.shares.forEach(x=>{
        dict[x.appId][1] = x
      })
      var apps = Object.keys(dict).map(function(key) {
        return dict[key]
      });
      
      // Sort the array based on the second element
      apps.sort(function(first, second) {
        if(second[1] == null && first[1] == null)
        {
          return 0
        }
        else if(second[1] == null)
        {
          return -1
        }
        else if(first[1] == null)
        {
          return 1
        }
        else{
        return second[1].numShares - first[1].numShares
        }
      });
      setData({apps, total})
      setIsLoading(false)
    }
    else{
      setData(null)
      setIsLoading(false)
    }
  }, [])

  return (
    <>
      {isLoading && (<p>loading...</p>)}
      {!isLoading && data == null && (<p>no wallet data</p>)}
      {!isLoading && data != null && (
        <div className="mainContent">
          <Total total={data.total} 
                 apps={data.apps}/>
                <hr className="break mt-8 mx-2"/>
          <div className="bg-transparent mx-1 mt-10 p-2 flex text-gray-400 flex-row justify-between">
            <h1>Apps</h1>
            <p onClick={()=>setViewAll(!viewAll)}>{viewAll ? 'View less' : 'View all'}</p>
          </div>
          {viewAll ? data.apps.map(x => <AppShare key={x[0].id} app={x}/>) : data.apps.slice(0,4).map(x => <AppShare key={x[0].id} app={x}/>)}
        </div>
      )}
    </>
     
  );
}

export default Wallet;
