import React from 'react'
import PropTypes from 'prop-types'

function Appstore({loggedIn}) {
    const hidden = loggedIn ? 'text-white text-xl font-bold px-1 text-center mt-4 rounded hidden' : 'text-white text-xl font-bold px-1 text-center mt-4 rounded'
    return (
        <div className="flex flex-col items-center justify-center">
            <h1 className="text-white text-xl font-bold px-1 text-center mt-4 rounded">Appstore</h1>
            <button
                type="button"
                className={hidden}
                onClick={()=>window.location.href =`https://signup.joineconomy.com?redirect=${window.location.href}`}>
                    <h2 >sign in/sign up for Economy</h2>
            </button>
        </div>
    );
}

Appstore.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
}
export default Appstore;