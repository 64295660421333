import React, { useEffect, useState }  from 'react'
import Wallet from './wallet/wallet'
import Appstore from './appstore/appstore'
import Settings from './settings/settings'
import { loginUser, logoutUser } from './api'
import NavBar from './navBar'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  const [user, setUser] = useState(null)
  const [isLoading, setLoading] = useState(true)

  const getUser = async () => {
    const res = await loginUser()
    if (res?.status === 200) {
      const resJson = await res.json()
      return resJson.user
    }
    return null
  }
  const signOut = async () => {
    await logoutUser()
    setUser(null)
  }
  useEffect(async () => {
    const user2 = await getUser()
    setUser(user2)
    setLoading(false)
  }, [])

  return (
      <>
        {isLoading && (
          <p>loading...</p>
        )}
        { !isLoading && user !== null && (
          <Router>
            <Switch>
              <Route path="/wallet">
                <Wallet/>
              </Route>
              <Route path="/settings">
                <Settings func={signOut} />
              </Route>
              <Route path="/">
                <Appstore loggedIn={true}/>
              </Route>
            </Switch>
            <NavBar loggedIn={true}/>
          </Router>
        )}
        { !isLoading && user === null && (
          <Router>
            <Switch>
              <Route path="/">
                <Appstore loggedIn={false}/>
              </Route>
            </Switch>
            <NavBar loggedIn={false}/>
          </Router>
        )}
      </>   
  );
}

export default App;
