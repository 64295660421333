import React from 'react'
import PropTypes from 'prop-types'
import DonutChart from 'react-donut-chart';

function Total ({ total, apps}) {
    const dataArr = apps.slice(0,7).map(x=> ({'label': x[0].name, 'value': x[1] != null ? x[1].numShares : 0}))
    let data = dataArr.filter(x=>x.value > 0)
    if (apps.length > 8 && data[7] > 0) {
        const sum = apps.slice(7).map(item => item[1] != null ? item[1].numShares : 0).reduce((prev, next) => prev + next)
        data.push({'label': 'other', 'value':sum})
    }
    return (
    <div className="customBackground flex flex-row justify-around m-2 mt-10 p-2 shadow-2xl rounded-2xl">
        <div className="flex text-center flex-col content-center justify-center align-center whitespace-nowrap text-sm md:text-lg lg:text-lg">
            <p className="font-extrabold">ACTIVE BALANCE - SHARES</p>
            <p className="text-xl">{total}</p>
        </div>
        <DonutChart
            data={data}
            selectedOffset={0.00}
            height={180}
            width={150}
            colors={['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600']}
            legend={false}
            innerRadius={0.45} 
            toggledOffset={0.06}
            formatValues={(values, total) => values === 1 ? `${values} share` : `${values} shares`}
            onClick={(item, selected)=> 
                { 
                    var element = document.getElementsByClassName("donutchart-innertext")[0];
                    element.classList.toggle("visible")
                    return selected ? item : null
                }
            }
        />
    </div>
    )
}

Total.propTypes = {
    total: PropTypes.number.isRequired,
    apps: PropTypes.array.isRequired,
}

export default Total