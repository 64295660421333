import React from 'react'
import PropTypes from 'prop-types'

const AppShare = ({ app }) => (
  <div className="customBackground flex flex-row justify-between mx-2 p-2 justify-center items-center content-center shadow-2xl rounded-2xl">
    <div className="flex text-center flex-col content-center justify-center align-middle items-center whitespace-nowrap text-sm md:text-lg lg:text-lg">
      <img src={app[0].icon} style={{ width: '40px', height: '40px' }} alt="img"/>
      <h2>{app[0].name}</h2>
    </div>
    <div className="flex flex-row text-center items-center align-middle">
      <p className="align-middle">{app[1] != null ? (app[1].numShares === 1 ? `${app[1].numShares} share` : `${app[1].numShares} shares`) : `0 shares`}</p>
    </div>
  </div>
)

AppShare.propTypes = {
    app: PropTypes.array.isRequired,
}

export default AppShare